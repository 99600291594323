<template>
    <div v-if="payStatus" class="modal-win__inner">
        <img src="/assets/images/success.svg" alt="">
        <p>Платеж успешно совершен</p>
    </div>
    <div v-else class="modal-win__inner">
        <img src="/assets/images/alert.svg" width="40" alt="">
        <p>Не удалось совершить платеж</p>
    </div>
</template>

<script>
export default {
    // props: ['status'],
    emits: ['showModal'],
    name: 'modal-success',
    computed: {
        payStatus() {
            return this.$store.state.payStatus
        },
    }
}
</script>