<template>
    <div class="title">Счета</div>
    <div v-if="bills && currentAccount">
        <div v-if="currentBill" class="bills-list">
            <user-bill-item v-for="(item, i) in currentBill" :key="i" :item="item" :all="currentBill.length" :current="i" />
        </div>
        <div v-else>Загрузка...</div>
    </div>
    <div v-else>Нет счетов</div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'user-bills',
    computed: {
        currentAccount() {
            return this.$store.state.currentAccount
        },
        bills() {
            this.$store.commit('getBills', this.currentAccount.account_id)
            return this.$store.state.bills
        },
        currentBill() {
            let bill
            let allUnpaid = false

            if (this.currentAccount) {
                bill = this.$store.getters.currentBill
                if (bill) {
                    bill.forEach((item, i) => {
                        if (item.message)
                            item.no_bill = true

                        if (this.currentAccount.customer_class != 'QL' && !item.no_bill) {
                            if ((this.currentAccount.saldo.balance.status == 'debit' && Number(this.currentAccount.saldo.balance.currentBalance) > Number(item.calcRows.calcRow1.calcSum) && i == 0) || this.currentAccount.saldo.balance.status == 'debit' && !item.calcRows.calcRow1.calcSum && i == 0) {
                                allUnpaid = true
                            }

                            item.unpaid = allUnpaid

                            if (this.currentAccount.saldo.balance.status == 'debit' && item.calcRows.calcRow1.calcSum && i == 0) {
                                item.unpaid = true
                            }

                        } else {
                            for (let key in item.fts) {
                                if (item.fts[key].duedate)
                                    item.fts[key].date = moment(item.fts[key].duedate, 'YYYY-MM-DD-H.m.s').format('DD.MM.YYYY')
                            }
                        }
                    })
                }
            }

            return bill
        }
    } 

}
</script>