<template>
    <ul v-if="payments.length" v-for="(pay, i) in payments[0].results" :key="i" class="payment-item flex">
        <li class="payment-date">{{getDate(pay.payDate)}}</li>
        <li class="payment-type">{{pay.tenderSource}}</li>
        <li class="payment-status">Зачислен</li>
        <li class="payment-summ">{{pay.tenderAmount}} руб.</li>
    </ul>
    <ul v-else-if="payments && payments.payId" class="payment-item flex">
        <li class="payment-date">{{getDate(payments.payDate)}}</li>
        <li class="payment-type">{{payments.tenderSource}}</li>
        <li class="payment-status">Зачислен</li>
        <li class="payment-summ">{{payments.tenderAmount}} руб.</li>
    </ul>
    <div v-else-if="!payments.length">Загрузка...</div>
    <div v-if="payments[0] && payments[0].rowCount == 0">Нет истории платежей</div>
</template>

<script>
import moment from 'moment'
moment.locale('ru')

export default {
    name: 'user-payments',
    data() {
        return {
            token: this.$store.state.token,
            apiUrl: this.$store.state.apiUrl,
            dataUser: this.$store.state.dataUser,
            currentAccount: this.$store.state.currentAccount,
            
        }
    },
    mounted() {
        
    },
    computed: {
        payments() {
            let accountId = this.$store.state.currentAccount.account_id
            let date = this.getDate()

            this.$store.commit('getPayments', {accountId, date})
            let payments = this.$store.state.payments.filter(item => item.accountId == accountId)

            return this.$store.state.payments.filter(item => item.accountId == accountId)
        }
    },
    methods: {
        getDate(date) {
            if (!date) {
                return {
                    startDate: moment().add(-6, 'month').set('date', 1).format('YYYY-MM-DD'),
                    dateNow: moment().format('YYYY-MM-DD')
                }
            } else {
                return moment(date).format('DD.MM.Y')
            }
        }
    }
}
</script>