<template>
    <auth-reg-window />
</template>

<script>
import AuthRegWindow from '@/components/AuthReg/AuthRegWindow'

export default {
    components: {
        AuthRegWindow
    },
    mounted() {
        
    }
}
</script>
