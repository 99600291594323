<template>
    <div class="modal-title">Редактирование адреса<br> для получения квитанции</div>
    {{dataClientNumber}}
    <div class="modal-account__data address" :data-address-value="dataAddressValue">
        <p>Адрес лицевого счета</p>
        <span>{{dataAddressValue ? dataAddressValue : 'Не установлен'}}</span>
    </div>
    <form class="modal-form">
        <input-text
            label       = "Населенный пункт"
            required    = ""
            :errorText  = "updateError"
            placeholder = "Населенный пункт"
            name        = "city"
            disabled    = "inputDisabled"
            @onComplete = "getValue"
        />
        <input-text
            label       = "Улица"
            required    = ""
            :errorText  = "updateError"
            placeholder = "Улица"
            name        = "street"
            disabled    = "inputDisabled"
            @onComplete = "getValue"
        />
        <div class="input-wrap__col flex">
            <input-text
                label       = "Дом"
                required    = ""
                :errorText  = "updateError"
                placeholder = "Дом"
                name        = "houseNumber"
                disabled    = "inputDisabled"
                @onComplete = "getValue"
            />
            <input-text
                label       = "Квартира"
                required    = ""
                :errorText  = "updateError"
                placeholder = "Квартира"
                name        = "apartment"
                disabled    = "inputDisabled"
                @onComplete = "getValue"
            />
        </div>
        <input-text
            label       = "Индекс"
            required    = ""
            :errorText  = "updateError"
            placeholder = "Индекс"
            name        = "postCode"
            disabled    = "inputDisabled"
            @onComplete = "getValue"
        />
        <div class="modal-button flex">
            <orange-button
                text            = "Сохранить"
                @click.prevent  = "addressBillUpdate"
                ref             = "submitButton"
            />
            <button class="button reset" @click.prevent="$emit('showModal')">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"

export default {
    name: 'modal-edit-address-bill',
    props: ['dataClientNumber', 'dataAddressValue'],
    emits: ['showModal'],
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            updateError: false,
            address: {
                city: '',
                street: '',
                houseNumber: '',
                apartment: '',
                postCode: '',
                toArray: function() {
                    let arr = []

                    this.postCode ? arr.push(this.postCode) : false
                    this.city ? arr.push(this.city) : false
                    this.street ? arr.push(this.street) : false
                    this.houseNumber ? arr.push(this.houseNumber) : false
                    this.apartment ? arr.push(this.apartment) : false

                    return arr
                }
            }
        }
    },
    computed: {
        currentAccount() {
            return this.$store.state.currentAccount
        }
    },
    methods: {
        getValue(e) {
            switch (e.name) {
                case 'city':
                    this.address.city = e.value
                    break;
                case 'street':
                    this.address.street = e.value
                    break;
                case 'houseNumber':
                    this.address.houseNumber = e.value
                    break;
                case 'apartment':
                    this.address.apartment = e.value
                    break;
                case 'postCode':
                    this.address.postCode = e.value
                    break;
            }
        },
        async addressBillUpdate() {
            try {
                let modal = document.getElementsByClassName('modal-win')[0]
                let client_number = modal.attributes['data-client-number'] ? modal.attributes['data-client-number'].value : this.currentAccount.client_number
                let bill_address = this.address.toArray().join(', ')
                
                let data = {
                    accounts: [
                        {
                            client_number,
                            bill_address
                        }
                    ]
                }

                const response = await axios.patch(
                    this.apiUrl + '/api/user/accounts',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                this.$emit('showModal')
                this.$store.commit('setAccountAddressBill', {client_number, bill_address})
            } catch (e) {
                if (e.response.data.message)
                    this.updateError = e.response.data.message

                console.log(e.response)
            }
        },
    }
}
</script>