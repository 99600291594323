<template>
    <div class="user-menu">
        <a @click.prevent="selectMenu('prev')" href="#" class="user-menu__nav prev"></a>
        <div class="user-menu__wrap">
            <div class="user-menu__scroll">
                <router-link class="user-menu-link" active-class="active" @click.prevent="setPointer" to="/">Уведомления</router-link>
                <router-link class="user-menu-link" active-class="active" @click.prevent="setPointer" to="/history/">История</router-link>
                <router-link class="user-menu-link" active-class="active" @click.prevent="setPointer" to="/bills/">Счета</router-link>
                <router-link class="user-menu-link" active-class="active" @click.prevent="setPointer" to="/feedback/">Обращения</router-link>
                <router-link class="user-menu-link" active-class="active" @click.prevent="setPointer" to="/doc/">Документы</router-link>
                <span class="user-menu__pointer"></span>
            </div>
        </div>
        <a @click.prevent="selectMenu('next')" href="#" class="user-menu__nav next"></a>
    </div>
</template>

<script>
export default {
    name: 'user-menu',
    data() {
        return {
            scrollPos: 0,
            scrollX: 0,
            pages: ['/', '/history/', '/bills/', '/feedback/', '/doc/'],
            currentPage: this.$route.href
        }
    },
    computed: {
        currentAccount() {
            return this.$store.state.currentAccount
        },
    },
    watch: {
        scrollX() {
            console.log(this.scrollX)
        }
    },
    mounted() {
        let active = document.getElementsByClassName('router-link-exact-active')[0]
        this.setPointer(active, true)
        this.scrollMenu()
    },
    methods: {
        setPointer(e, mounted) {
            let userMenu = document.getElementsByClassName('user-menu__scroll')[0]
            let menuPointer = document.getElementsByClassName('user-menu__pointer')[0]

            let wWidth = window.innerWidth
            let addLeft = 0

            let element
            if (!mounted)
                element = e.target
            else
                element = e

            if (element && userMenu) {
                let elementLeft = element.getBoundingClientRect().left
                let userMenuLeft = userMenu.getBoundingClientRect().left 
                let elementWidth = element.offsetWidth

                menuPointer.style.width = elementWidth + 'px'
                menuPointer.style.left = elementLeft - userMenuLeft + 'px'
            }
        },
        selectMenu(direct) {
            this.pages.forEach((val, i) => {
                switch (direct) {
                    case 'next':
                        if (val == this.$route.href && this.pages[i + 1]) {
                            this.$router.push(this.pages[i + 1])
                            this.currentPage = this.pages[i + 1]
                        }
                        break;
                    case 'prev':
                        if (val == this.$route.href && this.pages[i - 1]) {
                            this.$router.push(this.pages[i - 1])
                            this.currentPage = this.pages[i - 1]
                        }    
                        break;
                }
            })

            this.scrollMenu(direct)
        },
        scrollMenu(direct) {
            let scrollBlock = document.getElementsByClassName('user-menu__scroll')[0]

            let _currentItem = Array.prototype.slice.call(document.getElementsByClassName('user-menu-link'))
            let currentItem
            _currentItem.forEach((val, i) => {
                if (val.getAttribute('href') == this.currentPage) {
                    currentItem = _currentItem[i]
                }
            })
            
            let findPos
            if (currentItem)
                findPos = (currentItem.getBoundingClientRect().left - scrollBlock.getBoundingClientRect().left) - (scrollBlock.offsetWidth - currentItem.offsetWidth) + 35

            if (findPos < 0 && direct == 'prev') {
                findPos = Math.abs(findPos)
            }

            let count = 0
            let timer = setInterval(() => {
                if (direct == 'next' || !direct)
                    scrollBlock.scrollLeft += 1
                else
                    scrollBlock.scrollLeft -= 1

                count++
                
                if (count >= findPos) {
                    clearInterval(timer)
                }
            }, 1)
        }
    }
}
</script>