<template>
    <div class="modal-title">Редактирование телефона</div>
    <form class="modal-form">
        <input-text
            label       = "Номер телефона"
            required    = "*"
            :errorText  = "updateError"
            placeholder = "+7 (___) ___-__-__"
            maskVal     = "phone"
            name        = "phone"
            ref         = "phoneInput"
            disabled    = "inputDisabled"
            :defValue   = "phone"
            @onComplete = "getValue"
        />
        <div class="modal-button flex">
            <orange-button
                text            = "Сохранить"
                @click.prevent  = "userUpdate"
                ref             = "submitButton"
            />
            <button class="button reset" @click.prevent="$emit('showModal')">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"
import moment from 'moment'

export default {
    name: 'modal-edit-phone',
    emits: ['showModal'],
    data() {
        return {
            dataUser: this.$store.state.dataUser,
            phone: this.$store.state.dataUser.phone,
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            updateError: false
        }
    },
    methods: {
        async userUpdate() {
            try {
                let data = {}

                if (this.phone) {
                    data.user = {...this.dataUser}
                    data.user.phone = this.phone
                    data.user.birthday = moment(data.user.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
                } else {
                    data = {...this.dataUser}
                    data.birthday = moment(data.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
                }

                const response = await axios.patch(
                    this.apiUrl + '/api/user',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                this.$emit('showModal')
                this.$store.commit('getUserInfo')
            } catch (e) {
                console.log(e)
                if (e.response.data.message)
                    this.updateError = e.response.data.message

                console.log(e.response)
            }
        },
        getValue(e) {
            this.phone = e.value
        },
        setInputDisabled(status) {
            if (this.$refs.submitButton)
                this.$refs.submitButton.setDisabled(status)
        },
    }
}
</script>