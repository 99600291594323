<template>
    <div v-if="!regVerify">
        <input-text
            label       = "Номер телефона"
            required    = "*"
            :errorText   = "regErrors.phone ? regErrors.phone[0] : ''"
            placeholder = "+7 (___) ___-__-__"
            maskVal     = "phone"
            name        = "phone"
            ref         = "phoneInput"
            disabled    = "inputDisabled"
            :defValue   = "phone"
            @onComplete = "getValue"
            @onAccept   = "clearValue"
        />
        <input-text
            label       = "Номер клиента"
            placeholder = "ххх хх хх"
            maskVal     = "client-number"
            name        = "client-number"
            disabled    = "inputDisabled"
            ref         = "clientNumberInput"
            textHint    = "7-ми значный номер клиента расположен в верхнем поле квитанции справа под именем и фамилией"
            :errorText  = "regErrors.client_number ? regErrors.client_number[0] : ''"
            :defValue   = "clientNumber"
            :maxlength  = "7"
            @onComplete = "getValue"
            @onAccept   = "clearValue"
        />
        <select-list
            v-if        = "this.clientNumber"
            label       = "Статус отношения к ЛС"
            :errorText  = "clientTypeError"
            :list       = "userType"
            placeholder = "Выбрать"
            @listChange = "listChange"
        />
        <input-password
            label       = "Пароль"
            required    = "*"
            :errorText   = "regErrors.password ? regErrors.password[0] : ''"
            placeholder = "Пароль"
            name        = "password"
            ref         = "passwordInput"
            @onComplete = "getValue"
        />
        <input-password
            label       = "Подтвердите пароль"
            required    = "*"
            :errorText   = "regErrors.password ? regErrors.password[0] : ''"
            placeholder = "Пароль"
            name        = "repassword"
            ref         = "repasswordInput"
            @onComplete = "getValue"
        />
        
        <input-checkbox
            text        = "Согласен с условиями <a href='/docs/ЛКК_обработка данных_текст_v2 (003).docx'>Пользовательского соглашения</a>"
            name        = "agreement"
            errorText   = "Установите флажок"
            @onComplete = "getValue"
            ref         = "checkboxInput"
        />
        <span v-if="regErrors.message" class="input-error">{{regErrors.message}}</span>
        <div class="reg-auth__button">
            <orange-button
                text            = "Зарегистрироваться"
                @click.prevent  = "register"
                ref             = "submitButton"
            />
        </div>
    </div>
    <div v-else>
        <a
            href="#"
            class="backward-button"
            @click.prevent="this.$store.commit('regStep1')"
        >
            <i>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 6.8C11.4418 6.8 11.8 6.44183 11.8 6C11.8 5.55817 11.4418 5.2 11 5.2V6.8ZM0.434315 5.43431C0.121895 5.74673 0.121895 6.25327 0.434315 6.56569L5.52548 11.6569C5.8379 11.9693 6.34443 11.9693 6.65685 11.6569C6.96927 11.3444 6.96927 10.8379 6.65685 10.5255L2.13137 6L6.65685 1.47452C6.96927 1.1621 6.96927 0.655565 6.65685 0.343146C6.34443 0.0307264 5.8379 0.0307264 5.52548 0.343146L0.434315 5.43431ZM11 5.2L1 5.2V6.8L11 6.8V5.2Z"></path>
                </svg>
            </i>
            Назад
        </a>
        <div class="confirmation-code-hint">Код подтверждения был отправлен на номер <span>{{maskPhone}}</span></div>
        <input-text
            label       = "Код подтверждения"
            required    = "*"
            :errorText   = "verifyErrors ? 'no-text' : ''"
            placeholder = "Введите код"
            maskVal     = ""
            name        = "verify-code"
            ref         = "verifyInput"
            disabled    = "inputDisabled"
            @onComplete = "getValue"
        />
        <ul v-if="verifyErrors && !verifyErrors.message" class="error-list">
            <li class="input-error"
                v-for="(error, i) in verifyErrors"
                :v-bind="i"
            >{{error[0]}}</li>
        </ul>
        <p v-else>{{verifyErrors.message}}</p>
        <div class="reg-auth__button">
            <orange-button
                text            = "Подтвердить"
                @click.prevent  = "verify"
                ref             = "submitButton"
            />
        </div>
    </div>
</template>

<script>
import InputText from '@/components/UI/InputText'
import InputPassword from '@/components/UI/InputPassword'
import OrangeButton from '@/components/UI/OrangeButton'
import InputCheckbox from '@/components/UI/InputCheckbox'

export default {
    name: 'reg-form',
    components: {
        InputText,
        InputPassword,
        OrangeButton,
        InputCheckbox,
    },
    data() {
        return {
            phone: '',
            maskPhone: '',
            clientNumber: '',
            password: '',
            repassword: '',
            agreement: false,
            verifyCode: '',
            clientType: false,
            clientTypeError: false,
            userType: [
                'Собственник',
                'Арендатор',
                'Зарегистрированный',
                'Проживает',
                'Другое',
            ],
        }
    },
    computed: {
        regVerify() {
            if (this.$store.state.verifyOk) {
                this.$store.commit('regStep1')
                this.$store.commit('login', {phone: this.phone, password: this.password, reload: true})
            }

            return this.$store.state.regVerify
        },
        regErrors() {
            if (this.$store.state.regErrors)
                this.setInputDisabled(false)

            return this.$store.state.regErrors
        },
        verifyErrors() {
            if (this.$store.state.verifyErrors)
                this.setInputDisabled(false)

            return this.$store.state.verifyErrors
        },
    },
    methods: {
        getValue(e) {
            if (e.name == 'phone') {
                this.phone = e.value
                this.maskPhone = e.maskValue
            }

            if (e.name == 'client-number')
                this.clientNumber = e.value
            
            if (e.name == 'password')
                this.password = e.value
            
            if (e.name == 'repassword')
                this.repassword = e.value

            if (e.name == 'agreement') {
                this.agreement = e.checked
            }
            if (e.name == 'verify-code') {
                this.verifyCode = e.value
            }
        },
        clearValue(e) {
            if (e.name == 'phone')
                this.phone = ''
            
            if (e.name == 'client-number')
                this.clientNumber = ''
        },
        register() {
            let error = false
            if (this.clientNumber && !this.clientType) {
                error = true
                this.clientTypeError = 'Выберите тип отношения к ЛС'
            } else
                this.clientTypeError = false
            
            if (!this.agreement)
                this.$refs.checkboxInput.showError()
            else
                this.$refs.checkboxInput.hideError()

            let data = {
                phone: this.phone,
                password: this.password,
                password_confirmation: this.repassword,
            }

            if (this.$refs.clientNumberInput.value) {
                let number = this.$refs.clientNumberInput.value
                number = number.split(' ').join('')

                data.client_number = number
                data.relation_type = this.clientType
            } else {
                // data.client_number = '0000000'
                // data.relation_type = 'Другое'
            }
            
            if (!error) {
                this.$store.commit('register', data)
                this.setInputDisabled(true)
            }
        },
        verify() {
            this.$store.commit('verify', {
                phone: this.phone,
                verification_code: this.verifyCode
            })
            this.setInputDisabled(true)
        },
        setInputDisabled(status) {
            if (this.$refs.submitButton)
                this.$refs.submitButton.setDisabled(status)
        },
        backAuth(e) {
            this.$emit('backAuth', this)
        },
        listChange(e) {
            this.clientType = e.value
        },
    }
}
</script>