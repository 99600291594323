<template>
    <div class="modal-title">Дополнительный номер</div>
    <form class="modal-form">
        <input-text
            label       = "Телефон"
            :errorText  = "updateError"
            placeholder = "Телефон"
            maskVal     = "phone"
            name        = "phone"
            :defValue   = "dataUser.extra_phone"
            @complete   ="complete"
        />
        <div class="modal-button flex">
            <orange-button
                text            = "Сохранить"
                @click.prevent  = "addPhoneUpdate"
                ref             = "submitButton"
            />
            <button class="button reset" @click.prevent="$emit('showModal')">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"

export default {
    name: 'modal-additional-phone',
    emits: ['showModal'],
    data() {
        return {
            phone: '',
            updateError: false,
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
        }
    },
    computed: {
        dataUser() {
            return this.$store.state.dataUser
        }
    },
    methods: {
        complete(e) {
            this.phone = e.target.value
        },
        async addPhoneUpdate() {
            try {
                if (!this.phone)
                    this.updateError = 'Укажите номер телефона'
                else {
                    const response = await axios.patch(
                        this.apiUrl + '/api/user',
                        {
                            user: {extra_phone: this.phone}
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.token
                            }
                        }
                    )
                    this.$emit('showModal')
                    this.$store.commit('getUserInfo')
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>