import {createRouter, createWebHistory} from "vue-router"
import store                            from '@/store'
import Auth                             from '@/pages/Auth'
import MainPage                         from '@/pages/MainPage'
import Profile                          from '@/pages/Profile'
import PageNotFound                     from '@/pages/PageNotFound'
import AccountPage                      from '@/pages/AccountPage'
import PayPage                          from '@/pages/PayPage'
import MeterReading                     from '@/pages/MeterReading'
import HistoryPage                      from '@/components/HistoryPage'
import UserNotice                       from '@/components/User/UserNotice'
import UserBills                        from '@/components/User/UserBills'
import UserFeedback                     from '@/components/User/UserFeedback'
import UserDocs                         from '@/components/User/UserDocs'
import MaintenancePage                    from '@/pages/MaintenancePage'

const routes = [
    {
        path: '/auth/',
        name: 'Auth',
        component: Auth
    },
    {
        path: '/',
        component: MainPage,
        children: [
            {
                name: 'index',
                path: '/',
                component: UserNotice,
            },
            {
                name: 'history',
                path: 'history',
                component: HistoryPage,
            },
            {
                name: 'bills',
                path: 'bills',
                component: UserBills,
            },
            {
                name: 'feedback',
                path: 'feedback/',
                component: UserFeedback,
                props: (route) => ({id: route.query.id})
            },
            {
                name: 'doc',
                path: 'doc',
                component: UserDocs,
            },
        ]
    },
    {
        path: '/profile/',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/account/',
        name: 'account',
        component: AccountPage,
    },
    {
        path: '/pay/',
        name: 'pay-page',
        component: PayPage,
    },
    {
        path: '/meter/',
        name: 'meter-reading',
        component: MeterReading,
    },
    {
        path: '/maintenance/',
        name: 'maintenance-page',
        component: MaintenancePage,
    }
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})

router.afterEach((to, from) => {
    if (to.query.orderId) {
        let orderId = localStorage.getItem('orderId')

        if ((orderId && orderId != to.query.orderId) || !orderId) {
            orderId = to.query.orderId
            localStorage.setItem('orderId', orderId)
            store.state.showPayWin = true
            store.state.payStatus = to.query.status == 'true' ? true : false

            store.commit('updatePayment', {orderId})
        }
    }
})

export default router