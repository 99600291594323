<template>
    <div class="container flex">
        <side-bar />
        <div class="content">
            <user-bar :hiddenBar="true" />
            <div class="content-wrap">
                <a href="#" @click.prevent="backPage()" class="backward-button large">
                    <i>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 6.8C11.4418 6.8 11.8 6.44183 11.8 6C11.8 5.55817 11.4418 5.2 11 5.2V6.8ZM0.434315 5.43431C0.121895 5.74673 0.121895 6.25327 0.434315 6.56569L5.52548 11.6569C5.8379 11.9693 6.34443 11.9693 6.65685 11.6569C6.96927 11.3444 6.96927 10.8379 6.65685 10.5255L2.13137 6L6.65685 1.47452C6.96927 1.1621 6.96927 0.655565 6.65685 0.343146C6.34443 0.0307264 5.8379 0.0307264 5.52548 0.343146L0.434315 5.43431ZM11 5.2L1 5.2V6.8L11 6.8V5.2Z"></path>
                        </svg>
                    </i>
                    Передача показаний
                </a>
                <div v-if="currentAccount && meters.length" class="testimony-wrap flex">
                    <div class="testimony">
                        <div v-for="meterAr in meters" class="testimony-list">
                            <div class="testimony-address">{{meterAr.fullAddress}}</div>
                            <div v-for="meter in meterAr.spList" class="testimony-table">
                                <div class="testimony-body">
                                    <ul>
                                        <li>
                                            <div class="electric-meter">Счетчик № {{meter.mtrDetails.serialNumber}}</div>
                                            <div class="testimony-hint">Ваш прибор учета поверен до {{validateDate(meter.mtrDetails.validate.lastValDate, meter.mtrDetails.validate.period)}}</div>
                                        </li>
                                        <li>
                                            <div v-if="Array.isArray(meter.mtrCfgDetails.registers)" v-for="v in meter.mtrCfgDetails.registers" class="meter-reading-name">
                                                <!-- <pre>{{v.registerId}}</pre> -->
                                                <span v-if="v.timeOfUse == 'PEAK'">Пик Т1</span>
                                                <span v-if="v.timeOfUse == 'DEM_PEAK'">Полупик Т3</span>
                                                <span v-if="v.timeOfUse == 'NIGHT'">Ночь Т2</span>
                                                <span v-if="v.timeOfUse == 'DAY'">День Т1</span>
                                            </div>
                                            <div v-else class="meter-reading-name">
                                                <span>Показания</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="testimony-hint">Метод</div>
                                            <div class="testimony-method">По показаниям</div>
                                        </li>
                                        <li>
                                            <div class="testimony-hint last-meter">Последние переданные показания, {{formatDate(meter.lastMeterRead.readDateTime)}}</div>
                                            <div v-if="meter.lastMeterRead.registerRead.registerReading" class="meter-reading-value">
                                                {{Number(meter.lastMeterRead.registerRead.registerReading)}}
                                            </div>
                                            <div v-else v-for="val in meter.mtrCfgDetails.registers" class="meter-reading-value">
                                                {{Number(val.registerReading)}}
                                            </div>
                                        </li>
                                        <!--  -->
                                        <li v-if="meter.mtrCfgDetails.registers.registerId" class="inputs">
                                            <div v-if="currentAccount.customer_class == 'QL'" class="testimony-hint">Текущие показания,<br> период передачи с 1 по конец месяца<span>*</span></div>
                                            <div v-else class="testimony-hint">Текущие показания,<br> период передачи с 15 по 25 число<span>*</span></div>
                                            <input-text
                                                placeholder         = "Введите показания"
                                                maskVal             = "number"
                                                :name               = "meter.mtrCfgDetails.registers.registerId"
                                                :dataReadsequence   = "meter.mtrCfgDetails.registers.seq"
                                                :dataMasterConfigId = "meter.mtrCfgDetails.meterConfigurationId"
                                                :dataPremiseId      = "meterAr.premiseId"
                                                :dataTariff         = "meter.lastMeterRead.tariff ? meter.mtrCfgDetails.registers.tariff : 1"
                                                :dataOldVal         = "Number(meter.lastMeterRead.registerRead.registerReading)"
                                                @keypress="inputChange"
                                                @keyup="inputChange"
                                            />
                                            <span class="input-error">Введите показания</span>
                                            <button @click.prevent="getMeterReadings" class="button send-meters" disabled>Передать показания</button>
                                        </li>
                                        <!--  -->
                                        <li v-else class="inputs">
                                            <div v-if="currentAccount.customer_class == 'QL'" class="testimony-hint">Текущие показания,<br> период передачи с 1 по конец месяца<span>*</span></div>
                                            <div v-else class="testimony-hint">Текущие показания,<br> период передачи с 15 по 25 число<span>*</span></div>
                                            <input-text
                                                v-for="val in meter.mtrCfgDetails.registers"
                                                placeholder         = "Введите показания"
                                                maskVal             = "number"
                                                :name               = "val.registerId"
                                                :dataReadsequence   = "val.seq"
                                                :dataMasterConfigId = "meter.mtrCfgDetails.meterConfigurationId"
                                                :dataPremiseId      = "meterAr.premId"
                                                :dataTariff         = "val.tariff"
                                                :dataOldVal         = "Number(val.registerReading)"
                                                @keypress="inputChange"
                                                @keyup="inputChange"
                                            />
                                            <span class="input-error">Введите показания</span>
                                            <button @click.prevent="getMeterReadings" class="button send-meters" disabled>Передать показания</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <aside class="testimony-sidebar">
                        <div class="sticky-block">
                            <div v-if="currentAccount.saldo.balance.status == 'debit'" class="testimony-sidebar__debt">
                                Задолженность: 
                                <span class="red-color">{{Math.abs(currentAccount.saldo.balance.currentBalance) + ' руб.'}}</span>
                            </div>
                            <div v-else class="testimony-sidebar__debt">
                                Переплата:
                                <span class="green-color">{{Math.abs(currentAccount.saldo.balance.currentBalance) + ' руб.'}}</span>
                            </div>

                            <div :class="{hidden: currentAccount.customer_class == 'QL'}">
                                <!-- meter.mtrCfgDetails.registers.registerId -->
                                <div v-for="meterAr in meters" class="expense-tariff">
                                    <ul v-for="meter in meterAr.spList">
                                        <li v-if="meter.mtrCfgDetails.registers.registerId">
                                            Расход: <span :id="meter.mtrCfgDetails.registers.registerId">0</span> кВтч
                                        </li>
                                        <li v-else v-for="(val, i) in meter.mtrCfgDetails.registers">
                                            Расход Т{{ i+1 }}: <span :id="val.registerId">0</span> кВтч
                                        </li>
                                    </ul>
                                    <ul v-for="meter in meterAr.spList">
                                        <li v-if="meter.mtrCfgDetails.registers.registerId">
                                            Тариф: {{meter.mtrCfgDetails.registers.tariff}} руб./кВтч
                                        </li>
                                        <li v-else v-for="(val, i) in meter.mtrCfgDetails.registers">
                                            Тариф Т{{ i+1 }}: {{val.tariff}} руб./кВтч
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="hidden">
                                <input-text
                                    v-if="currentAccount.customer_class != 'QL'"
                                    flex                = "flex"
                                    :readonly           = "true"
                                    class              = "all-summ"
                                    label               = "По показаниям"
                                    placeholder         = "По показаниям"
                                    maskVal             = "number"
                                />
                            </div>
                            <div v-if="currentAccount.customer_class != 'QL'" class="title">
                                К оплате: <span class="pay-summ">{{currentAccount.saldo.balance.status == 'debit' ? Math.abs(currentAccount.saldo.balance.currentBalance) + ' руб.' : '0 руб.'}}</span>
                            </div>
                            <button @click.prevent="payAction()" class="button pay-button"
                                :disabled="currentAccount.saldo.balance.status == 'credit'">Оплатить</button>
                        </div>
                    </aside>
                    <div class="text-center">
                        <button @click.prevent="getMeterReadings(e, true)" class="button send-all-meters" disabled>Передать все показания</button>
                    </div>
                </div>
                <div v-else>Нет подключенных счетчиков</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import axios from "axios"

export default {
    name: 'meter-reading',
    data() {
        return {
            token: this.$store.state.token,
            apiUrl: this.$store.state.apiUrl,
            sendMetersData: [],
            inputs: [],
            summ: 0,
        }
    },
    mounted() {
        this.fixBlock()
    },
    computed: {
        currentAccount() {
            if (this.$store.state.currentAccount)
                this.$store.commit('getPremisesMetersStatements')

            return this.$store.state.currentAccount
        },
        meters() {
            if (this.currentAccount) {
                let paySumm = document.getElementsByClassName('pay-summ')[0]
                let allSumm = document.getElementsByClassName('all-summ')[0]
                let payButton = document.getElementsByClassName('pay-button')[0]

                if (allSumm)
                    allSumm.value = ''

                if (paySumm)
                    if (this.currentAccount.saldo.balance.status == 'debit') {
                        paySumm.innerHTML = this.currentAccount.saldo.balance.currentBalance
                        payButton.disabled = false
                    } else {
                        paySumm.innerHTML = '0 руб.'
                        payButton.disabled = true
                    }
            }

            // console.clear();
            let tmpMeter = this.$store.getters.currentMeter;
            if (tmpMeter) {
                tmpMeter.forEach((meters, i) => {
                    meters.spList.forEach((spList, i2) => {
                        if (Array.isArray(spList.mtrCfgDetails.registers)) {

                            let arRegisterRead = [];
                            for (let i = 0; i < spList.mtrCfgDetails.registers.length; i++) {
                                arRegisterRead.push(i);
                            }

                            spList.mtrCfgDetails.registers.forEach((registerRead, i3) => {
                                let index;
                                switch (registerRead.timeOfUse) {
                                    case 'PEAK':
                                        index = 0;
                                        break;
                                    case 'DAY':
                                        index = 0;
                                        break;
                                    case 'NIGHT':
                                        index = 1;
                                        break;
                                    case 'DEM_PEAK':
                                        index = 2;
                                        break;
                                }
                                arRegisterRead[index] = registerRead;
                                
                                if (Array.isArray(spList.lastMeterRead.registerRead)) {
                                    spList.lastMeterRead.registerRead.forEach((v) => {
                                        if (v.registerId == registerRead.registerId) {
                                            registerRead.registerReading = v.registerReading
                                        }
                                    })
                                }
                            })

                            // tmpMeter[i].spList[i2].lastMeterRead.registerRead = arRegisterRead;
                            spList.mtrCfgDetails.registers = arRegisterRead;
                        }
                    })
                })
            }

            return tmpMeter
        }
    },
    methods: {
        validateDate(dateStr, period) {
            return moment(dateStr, 'DD-MM-YYYY').add(period, 'years').format('DD.MM.YYYY')
        },
        formatDate(dateStr) {
            let indexOf = dateStr.lastIndexOf('-')
            return moment(dateStr.slice(0, indexOf)).format('DD.MM.YYYY')
        },
        checkInput(input) {
            let errorCount = 0

            if (!input.value) {
                input.classList.add('error')
                input.parentNode.parentNode.classList.add('error')

                errorCount++
            } else {
                input.classList.remove('error')
                input.parentNode.parentNode.classList.remove('error')
            }

            return errorCount
        },
        getMeterReadings(e, all) {
            this.$store.state.loadDataStatus = true

            let meters = []
            let masterConfigId = ''
            let registers = []
            let errors = 0
            let premiseId = ''
            let inputs = ''
            let arInputs = []
            this.sendMetersData = []

            if (!all) {
                inputs = Array.prototype.slice.call(e.target.parentNode.getElementsByClassName('input-text'))
                registers = []
                inputs.forEach(input => {
                    if (input.tagName == 'INPUT') {
                        errors = this.checkInput(input)

                        masterConfigId = input.attributes['data-master-config-id'].value
                        premiseId = input.attributes['data-premise-id'].value
                        
                        registers.push({
                            registerId: input.attributes.name.value,
                            readSequence: input.attributes['data-readsequence'].value,
                            registerReading: input.value
                        })
                        
                    }
                })
                meters.push({
                    meterConfigurationId: masterConfigId,
                    registers
                })

                this.sendMetersData.push({
                    account_id: this.currentAccount.account_id,
                    premise_id: premiseId,
                    meters
                })

                if (!errors) {
                    this.inputs.push(inputs)
                }
            } else {
                let testimonyList = Array.prototype.slice.call(document.getElementsByClassName('testimony-list'))
                testimonyList.forEach(testimony => {
                    meters = []
                    inputs = Array.prototype.slice.call(testimony.getElementsByClassName('input-text'))
                    registers = []
                    inputs.forEach(input => {
                        errors = this.checkInput(input)

                        masterConfigId = input.attributes['data-master-config-id'].value
                        premiseId = input.attributes['data-premise-id'].value

                        registers.push({
                            registerId: input.attributes.name.value,
                            readSequence: input.attributes['data-readsequence'].value,
                            registerReading: input.value
                        })
                    })
                    meters.push({
                        meterConfigurationId: masterConfigId,
                        registers
                    })

                    this.sendMetersData.push({
                        account_id: this.currentAccount.account_id,
                        premise_id: premiseId,
                        meters
                    })

                    if (!errors) {
                        this.inputs.push(inputs)
                    }
                })
            }

            if (!errors) {
                this.sendMetersData.forEach(val => {
                })
                this.sendMeterReadings(this.sendMetersData)

                setTimeout(() => this.$store.state.loadDataStatus = false, 500)
            }
        },
        disabledButton(buttonEnabled, dataSent) {
            let buttons = []
            let countButtonDisable = 0

            this.inputs.forEach(val => {
                val.forEach(item => {
                    let button = Array.prototype.slice.call(item.parentNode.parentNode.parentNode.getElementsByClassName('send-meters'))[0]
                    buttons.push(button)
    
                    if (!buttonEnabled) {
                        item.disabled = true
                        button.disabled = true
                    } else {
                        item.disabled = false
                        button.disabled = false
                    }
    
                    if (dataSent) {
                        button.classList.add('reset')
                        button.innerHTML = 'Показания переданы'
                    }
                })
            })

            buttons.forEach(val => {
                if (val.disabled)
                    countButtonDisable++
            })

            if (buttons.length == countButtonDisable && dataSent) {
                let buttonAllSent = Array.prototype.slice.call(document.getElementsByClassName('send-all-meters'))[0]
                buttonAllSent.disabled = true
                buttonAllSent.classList.add('reset')
                buttonAllSent.innerHTML = 'Показания переданы'
            }

            // by kostyl
            let testimony = document.getElementsByClassName('testimony')[0]
            let inputs = Array.prototype.slice.call(testimony.getElementsByClassName('input-text'))
            inputs.forEach(input => {
                if (input.disabled) {
                    let button = input.parentNode.parentNode.parentNode.getElementsByClassName('send-meters')[0]
                    button.disabled = true
                    button.classList.add('reset')
                    button.innerHTML = 'Показания переданы'
                }
            })
        },
        async sendMeterReadings(data) {
            this.disabledButton()
            try {
                const response = await axios.post(
                    this.apiUrl + '/api/meters',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                
                this.disabledButton(false, true)
            } catch (e) {
                this.disabledButton(true)
                console.log(e);
            }
        },
        backPage() {
            this.$router.go(-1)
        },
        onComplete(e) {
            console.log(e);
        },
        inputChange() {
            this.summ = 0
            let inputs = document.getElementsByClassName('testimony')[0]
            let inputSumm = document.getElementsByClassName('all-summ')[0]
            let payButton = document.getElementsByClassName('pay-button')[0]
            let paySumm = document.getElementsByClassName('pay-summ')[0]
            let sendAllMetersButton = document.getElementsByClassName('send-all-meters')[0]
            let allButtons = Array.prototype.slice.call(document.getElementsByClassName('send-meters'))
            
            inputs = Array.prototype.slice.call(inputs.getElementsByClassName('input-text'))

            // if (this.currentAccount.saldo.balance.status == 'debit')
            //     this.summ = Number(this.currentAccount.saldo.balance.currentBalance)
                

            inputs.forEach(input => {
                let curButton = input.parentNode.parentNode.parentNode.getElementsByClassName('send-meters')[0]

                if (input.value) {
                    if (Number(input.value) < Number(input.attributes['data-old-val'].value)) {
                        input.classList.add('error')
                        curButton.disabled = true
                    } else {
                        input.classList.add('ok')
                        input.classList.remove('error')
                        curButton.disabled = false

                        let diff = input.value - input.attributes['data-old-val'].value;
                        this.summ += diff * input.attributes['data-tariff'].value
                        this.amounIndications += diff * input.attributes['data-tariff'].value

                        // console.log(diff);
                        let span = document.getElementById(input.attributes.name.value);
                        span.innerHTML = diff;
                    }
                } else {
                    input.classList.remove('ok')
                    curButton.disabled = true

                    let span = document.getElementById(input.attributes.name.value);
                    span.innerHTML = 0;
                }
            })

            let disabledFalseButtonCount = 0
            allButtons.forEach(btn => {
                !btn.disabled ? disabledFalseButtonCount++ : ''
            })

            if (allButtons.length == disabledFalseButtonCount)
                sendAllMetersButton.disabled = false
            else
                sendAllMetersButton.disabled = true

            if (this.summ) {
                if (inputSumm)
                    inputSumm.value = this.summ.toFixed(2)
                    // inputSumm.value = this.amounIndications.toFixed(2)

                payButton.disabled = false

                if (paySumm) {
                    let summ = this.summ
                    if (this.currentAccount.saldo.balance.status == 'debit') {
                        summ += Number(this.currentAccount.saldo.balance.currentBalance)
                    } else {
                        if (Math.abs(this.currentAccount.saldo.balance.currentBalance) > summ) {
                            summ = 0;
                        } else {
                            summ = summ - Math.abs(this.currentAccount.saldo.balance.currentBalance);
                        }
                    }

                    this.summ = summ
                    if (summ > 0) {
                        paySumm.innerHTML = summ.toFixed(2) + ' руб.'
                    } else {
                        paySumm.innerHTML = '0 руб.'
                        payButton.disabled = true
                    }
                }
            } else {
                if (inputSumm)
                    inputSumm.value = ''

                payButton.disabled = true

                if (paySumm) {
                    paySumm.innerHTML = this.currentAccount.saldo.balance.currentBalance < 0 ? '0 руб.' : this.currentAccount.saldo.balance.currentBalance + ' руб.';
                }
            }
        },
        async payAction() {
            try {
                const response = await axios.post(
                    this.apiUrl + '/api/pay',
                    {
                        account_id: this.currentAccount.account_id,
                        amount: this.summ * 100
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )

                window.location.href = response.data.paymentLink
            } catch (e) {
                console.log(e.response)
            }
        },
        fixBlock() {
            window.addEventListener('scroll', function() {
                let sidebar = document.getElementsByClassName('testimony-sidebar')
                if (sidebar) {
                    let sidebarTop = sidebar[0].getBoundingClientRect().top
                    let stickyBlock = document.getElementsByClassName('sticky-block')
                    
                    if (sidebarTop <= 0)
                        stickyBlock[0].style.marginTop = Math.abs(sidebarTop) + 'px'
                    else
                        stickyBlock[0].style.marginTop = '0px'
                }
            })
        }
    }
}
</script>

<style scoped>
.input-error{
    display: none;
    margin: 0 0 10px;
}
.error + .input-error{
    display: block;
}
</style>