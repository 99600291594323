<template>
    <div class="container flex">
        <side-bar />
        <div class="content">
            <header-block />
            <div class="content-wrap">
                <user-menu />
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        if (!this.$store.state.authStatus)
            this.$router.push('/auth/')
    }
}
</script>