<template>
	<div class="modal-title">Изменение пароля</div>
	<form class="modal-form">
        <input-password
            label       = "Текущий пароль"
            required    = "*"
            placeholder = "Пароль"
            name        = "old_password"
            :errorText	= "errorText.old_password"
        />
        <input-password
            label       = "Придумайте новый пароль"
            required    = "*"
            placeholder = "Пароль"
            name        = "password"
            :errorText	= "errorText.password"
        />
        <input-password
            label       = "Повтор нового пароля"
            required    = "*"
            placeholder = "Пароль"
            name        = "password_confirmation"
            :errorText	= "errorText.password_confirmation"
        />
        <div class="modal-button flex">
            <button @click.prevent="changePass" class="button">Сохранить</button>
            <button @click.prevent="$emit('showModal')" class="button reset">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"

export default {
	name: 'modal-change-password',
	emits: ['showModal'],
	data() {
		return {
			apiUrl: this.$store.state.apiUrl,
			token: this.$store.state.token,
			errorText: {
				old_password: '',
				password: '',
				password_confirmation: ''
			}
		}
	},
	methods: {
		async changePass() {
			let inputs = document.getElementsByClassName('input-text')
			this.errorText.old_password = ''
			this.errorText.password = ''
			this.errorText.password_confirmation = ''

			try {
                const response = await axios.post(
                    this.apiUrl + '/api/user/password',
                    {
                    	old_password: inputs.old_password.value,
						password: inputs.password.value,
						password_confirmation: inputs.password_confirmation.value,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                this.$emit('showModal')
                console.log(response)
            } catch (e) {
                if (e.response.data.errors) {
                	if (e.response.data.errors.old_password)
						this.errorText.old_password = e.response.data.errors.old_password[0]

					if (e.response.data.errors.password)
						this.errorText.password = e.response.data.errors.password[0]

					if (e.response.data.errors.password)
						this.errorText.password_confirmation = e.response.data.errors.password[1]
                } else if (e.response.data.message)
                	this.errorText.old_password = e.response.data.message

                console.log(e.response.data)
            }
		}
	}
}
</script>