<template>
    <header class="header">
        <div class="wrap flex">
            <div class="header__right">
                <div class="header__top flex">
                    <div class="header__top-info">
                        <div class="header__logo flex">
                            <img
                                :src="currentAccount.cis_division == 'GESK' ? '/assets/images/noviten-white.svg' : '/assets/images/logo-lesk-80x28.svg'"
                            alt="">
                            <div class="current-invoice" v-if="currentAccount">
                                <span>Номер клиента:</span>
                                <input-text
                                    :inputValue="currentAccount.client_number"
                                    maskVal="invoice"
                                />
                            </div>
                        </div>
                        <div v-if="currentAccount.person" class="header__address">{{currentAccount.person.results.adrGroup.fullAddress}}</div>
                        <div v-else class="header__address">Нет адреса</div>
                    </div>
                    <user-bar />
                </div>
                <div v-if="currentAccount.saldo" class="header__bottom flex">
                    <div v-if="currentAccount.saldo.balance.status == 'credit'" class="header__data">
                        <div class="hint">
                            <span>Задолженность отсутствует</span>
                            <popup-hint text="При наличии задолженности по лицевому счету не менее двух месячных начислений за два последних периода ЛЭСК имеет право приостановить подачу энергоснабжения" class="small" />
                        </div>
                        <div class="header__data-value"><span class="green-color">{{Math.abs(currentAccount.saldo.balance.currentBalance).toFixed(2)}}</span> руб.</div>
                        <router-link class="button" to="/pay/">Оплатить</router-link>
                    </div>
                    <div v-else class="header__data">
                        <div class="hint">
                            <span class="red-color">Задолженность</span>
                            <popup-hint text="При наличии задолженности по лицевому счету не менее двух месячных начислений за два последних периода ЛЭСК имеет право приостановить подачу энергоснабжения" class="small" />
                        </div>
                        <div class="header__data-value"><span class="red-color">{{Number(currentAccount.saldo.balance.currentBalance).toFixed(2)}}</span> руб.</div>
                        <router-link class="button indebt-button" to="/pay/">Оплатить</router-link>
                    </div>
                    <template v-if="currentAccount.customer_class != 'QL'">
                        <!-- До передачи показаний осталось -->
                        <div v-if="daysLeft && currentAccount.saldo.statement_stats.statements_transferred == false" class="header__data">
                            <div class="hint">
                                <span>До передачи показаний осталось</span>
                                <popup-hint :text="hintText" class="small" />
                            </div>
                            <div class="header__data-value">{{daysLeft + ' ' + declOfNum(daysLeft, ['день', 'дня', 'дней'])}}</div>
                            <router-link
                                class="button"
                                to="/meter/"
                                :class="{
                                    'white-border': currentAccount.saldo.balance.status == 'debit'
                                }"
                            >Передать показания</router-link>
                        </div>
    
                        <!-- Расчет произведен - Просрочено -->
                        <div v-if="!daysLeft && currentAccount.saldo.statement_stats.statements_transferred == false" class="header__data">
                            <div class="hint">
                                <span>Расчет произведен</span>
                                <popup-hint :text="hintText" class="small" />
                            </div>
                            <div class="header__data-value">
                                <span v-if="currentAccount.saldo.statement_stats.method == 'meter'" class="red-color">По счетчику</span>
                                <span v-else-if="currentAccount.saldo.statement_stats.method == 'avg'" class="red-color">По среднему</span>
                                <span v-else-if="currentAccount.saldo.statement_stats.norm == 'meter'" class="red-color">По нормативу</span>
                            </div>
                            <router-link class="button indebt-button" to="/meter/">Просрочено</router-link>
                        </div>
                        
                        <!-- Расчет произведен -->
                        <div v-if="currentAccount.saldo.statement_stats.statements_transferred == true" class="header__data">
                            <div class="hint">
                                <span>Расчет произведен</span>
                                <popup-hint :text="hintText" class="small" />
                            </div>
                            <div class="header__data-value">Показания переданы</div>
                            <router-link
                                class="button"
                                to="/meter/"
                                :class="{
                                    'white-border': currentAccount.saldo.balance.status == 'debit'
                                }"
                            >Передать показания</router-link>
                        </div>
                    </template>
                    <template v-else>
                        <!-- До передачи показаний осталось -->
                        <div v-if="daysLeft || !daysLeft" class="header__data">
                            <div class="hint">
                                <span>До передачи показаний осталось</span>
                                <popup-hint :text="hintText" class="small" />
                            </div>
                            <div class="header__data-value">{{daysLeft + ' ' + declOfNum(daysLeft, ['день', 'дня', 'дней'])}}</div>
                            <router-link
                                class="button"
                                to="/meter/"
                                :class="{
                                    'white-border': currentAccount.saldo.balance.status == 'debit'
                                }"
                            >Передать показания</router-link>
                        </div>
                    </template>
                    
                    <!-- Автоплатеж -->
                    <div v-if="currentAccount.customer_class != 'QL'" class="header__data">
                        <div class="hint">
                            <span>Автоплатеж</span>
                            <popup-hint text="Подключение данной услуги позволит 10 числа каждого месяца оплачивать электроэнергию путем автоматического списания средств со счета банковской карты." class="small" />
                        </div>
                        <div v-if="!currentAccount.saldo.autopay.status" class="header__data-value">Не подключен</div>
                        <div v-else class="header__data-value">Подключен</div>
                        <button
                            v-if="!currentAccount.saldo.autopay.status"
                            class="button"
                            :class="{
                                'white-border': currentAccount.saldo.balance.status == 'debit'
                            }"
                            @click.prevent="showModalAutoPay"
                        >Подключить</button>
                        <button v-else class="button white-border" @click.prevent="disableAutoPay">Отключить</button>
                    </div>
                    <div v-else-if="currentAccount.customer_class != 'QL'" class="header__data">
                        <div class="hint">
                            <span>Автоплатеж</span>
                            <popup-hint text="Подключение данной услуги позволит 10 числа каждого месяца оплачивать электроэнергию путем автоматического списания средств со счета банковской карты." class="small" />
                        </div>
                        <div class="header__data-value">Не подключен</div>
                        <button
                            class="button"
                            @click.prevent="showModalAutoPay"
                            :class="{
                                'white-border': currentAccount.saldo.balance.status == 'debit'
                            }"
                        >Подключить</button>
                    </div>
                </div>
            </div>
        </div>
        <modal-win type="autoPay" ref="modal" modalClass="wide-modal" />
    </header>
</template>

<script>
import moment from 'moment'
import axios from "axios"

export default {
    name: 'header-block',
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            hintText: 'Передача показаний за предыдущий месяц возможна до 25 числа текущего месяца. В случае если в расчетном периоде не поступили показания прибора учета, счет на оплату будет сформирован на основании среднемесячного объема потребления.'
        }
    },
    computed: {
        daysLeft() {
            let daysLeft

            if (this.currentAccount) {
                let now = moment()
                let dayNow = now.format('D')
                // let dayNow = 31
                let lastDay = this.currentAccount.customer_class == 'QL' ? Number(now.endOf('month').format('DD')) : 25

                // if(this.currentAccount.customer_class == 'QL')
                //     dayNow = 1
                // else
                //     dayNow = 26

                if (this.currentAccount.customer_class == 'QL' && dayNow == lastDay)
                    dayNow = lastDay - 1;

                if (dayNow >= 1 && dayNow <= lastDay) {
                    daysLeft = lastDay - dayNow
                }
            }
            
            return daysLeft
        },
        currentAccount() {
            return this.$store.state.currentAccount
        },
        saldo() {
            return this.$store.state.saldo
        },
        bindingsCard() {
            return this.$store.state.bindingsCard
        }
    },
    methods: {
        showModalAutoPay() {
            this.$refs.modal.showModal()
        },
        declOfNum(number, words) {
            return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]]
        },
        async disableAutoPay() {
            try {
                this.checkboxError = false
                const response = await axios.delete(
                    this.apiUrl + '/api/pay/autopay',
                    {
                        params: {
                            account_id: this.currentAccount.account_id
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                this.$store.state.accounts = []
                this.$store.commit('getAccounts')
                this.$store.getters.currentAccount(this.currentAccount.account_id)
                // console.log(response)
            } catch(e) {
                if (e.response)
                    this.error = e.response.data.message

                console.log(e);
            }
        }
    }
}
</script>