<template>
	<div class="modal-title">Создание обращения</div>
	<div class="modal-title__sub">Ваши контакты для связи</div>
	<form class="modal-form">
		<input-text
	        label       = "Номер телефона"
	        required    = "*"
	        placeholder = "+7 (___) ___-__-__"
	        maskVal     = "phone"
	        name        = "phone"
			:defValue	= "phone"
			@accept		= "onAccept"
	        @onComplete = "getValue"
	        :errorText	= "errorTextPhone"
	    />
	    <input-text
	        label       = "E-mail"
	        placeholder = "E-mail"
	        maskVal     = "email"
	        name        = "email"
			:defValue	= "this.$store.state.dataUser.email"
	        @onComplete = "getValue"
	        :errorText	= "errorTextEmail"
	    />
		<select-list
			:list="getFeedbackTheme"
			label="Тема"
			placeholder="Выберите тему"
			:errorText="errorTheme"
			@listChange="getFeedbackSection"
		/>
		<select-list
			:list="getFeedbackSections"
			label="Раздел"
			placeholder="Выберите раздел"
			:errorText="errorSection"
			@listChange="selectFeedbackSection"
		/>
	    <div class="input-wrap">
            <label class="input-label">Обращение<span class="required">*</span></label>
            <textarea v-model="text" class="input-text" placeholder="Подробное описание позволит нам предоставить ответ в кратчайшие сроки без уточнения дополнительной информации"></textarea>
            <span v-if="errorTextText" class="input-error">{{errorTextText}}</span>
        </div>
        <div class="modal-files">
        	<div v-if="files.length" v-for="(file, i) in files" class="modal-files__item flex">
        		<div class="modal-files__ico">
                    <img v-if="file.src" :src="file.src" alt="">
                </div>
                <div class="modal-files__data">
                    <p>{{file.name}}</p>
                    <span>{{file.size}}</span>
                </div>
                <a @click.prevent="delFile(i)" href="#" class="file-remove">
	                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	                    <path d="M11 8.5L17 2.5C17.5523 1.94772 17.5523 1.05228 17 0.5C16.4477 -0.0522847 15.5523 -0.0522853 15 0.5L9 6.5L3 0.5C2.44772 -0.0522845 1.55229 -0.0522848 1 0.5C0.447716 1.05229 0.447715 1.94771 1 2.5L7 8.5L0.999999 14.5C0.447715 15.0523 0.447715 15.9477 1 16.5C1.55228 17.0523 2.44771 17.0523 3 16.5L9 10.5L15 16.5C15.5523 17.0523 16.4477 17.0523 17 16.5C17.5523 15.9477 17.5523 15.0523 17 14.5L11 8.5Z"></path>
	                </svg>                            
	            </a>
        	</div>
        	<div class="input-file">
                <button class="button reset select-file">Прикрепить файл</button>
                <input type="file" @change="previewFiles" multiple accept="image/png, image/gif, image/jpeg, application/pdf">
            </div>
        </div>
        <div class="modal-button flex">
            <button
				@click.prevent="addFeddback"
				class="button"
				:disabled="inputDisabled"
			>Отправить</button>
        </div>
	</form>
</template>

<script>
import axios from "axios"

export default {
	name: 'modal-feedback',
	emits: ['showModal'],
	data() {
		return {
			files: [],
			text: '',
			title: '',
			phone: this.$store.state.dataUser.phone,
			email: this.$store.state.dataUser.email,
			errorTextPhone: false,
			errorTextText: false,
			errorTextEmail: false,
			errorTheme: false,
			errorSection: false,
			apiUrl: this.$store.state.apiUrl,
			token: this.$store.state.token,
			theme: false,
			section: false,
			inputDisabled: false
		}
	},
	computed: {
        getFeedbackTheme() {
			let feedbackCateg = this.$store.state.feedbackCateg

            if (!feedbackCateg)
                this.$store.commit('getFeedbackCateg')

            return this.$store.getters.getFeedbackTheme()
        },
		getFeedbackSections() {
			return this.$store.state.feedbackSections
		}
	},
	methods: {
		previewFiles(e) {
				let files = e.target.files

				for (var i = 0; i < files.length; i++) {
					let file = files.item(i)
					
					let fileObj = {
						src: file.type.indexOf('image') >= 0 ? URL.createObjectURL(file) : false,
						name: file.name,
						size: this.getFileSize(file.size),
						original: file
					}

				this.files.push(fileObj)
			}
		},
		getFileSize(size) {
			let fSExt = ['Bytes', 'Kb', 'Mb', 'Gb']
        	let i = 0

        	while (size > 900) {
        		size/=1024
        		i++
        	}

            return (Math.round(size * 100) / 100) + fSExt[i];
		},
		delFile(id) {
			this.files.splice(id, 1)
		},
		onAccept(e) {
			if (e.detail)
				this.phone = e.detail._unmaskedValue
		},
		getValue(e) {
			if (e.name == 'email')
				this.email = e.value
			else
				this.phone = e.value
		},
		getFeedbackSection(name) {
			this.theme = name.value
			this.$store.getters.getFeedbackTheme(name.value)
		},
		selectFeedbackSection(e) {
			this.section = e.value
		},
		async addFeddback() {
			if (!this.theme)
				this.errorTheme = 'Выберите тему обращения'
			else
				this.errorTheme = false

			if (!this.section)
				this.errorSection = 'Выберите тип обращения'
			else
				this.errorSection = false

			let formData = new FormData()

			formData.append('account_id', this.$store.state.currentAccount.account_id)
			formData.append('phone', this.phone)
			
			if (this.email)
				formData.append('email', this.email)

			formData.append('text', this.text)
			formData.append('category', this.theme)
			formData.append('theme', this.section)

			this.files.forEach(file => {
				formData.append('files[]', file.original, file.name)
			})

			try {
				this.inputDisabled = true
				const response = await axios.post(
					this.apiUrl + '/api/questions',
					formData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
				)
				this.$store.commit('getFeedback')
				this.$emit('showModal')
			} catch(e) {
				this.inputDisabled = false
				console.log(e)
				if (e.response.data) {
					if (e.response.data.errors.phone)
						this.errorTextPhone = e.response.data.errors.phone[0]
					else
						this.errorTextPhone = false

					if (e.response.data.errors.text)
						this.errorTextText = e.response.data.errors.text[0]
					else
						this.errorTextText = false
				}

				console.log(e.response);
			}
		}
	}
}
</script>