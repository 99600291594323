<template>
    <div class="modal-title">Заявление на автоплатеж</div>
    <p v-if="step1" class="modal-desc">Условия предоставления услуги “Автоплатеж” для держателей банковских карт</p>
    <form class="modal-form">
        <div v-if="step1" class="scroll-text">
            <div>
                1. ООО НОВИТЭН, именуемое в дальнейшем Поставщик, предоставляет Услугу «Автоплатеж» в соответствии с условиями Договора интернет-эквайринга. Банк - эквайрер (bank-acguirer) – ПАО Сбербанкбез взимания платы с плательщика.<br>
                2. Услуга «Автоплатеж» позволяет плательщикам оплачивать электроэнергию банковскими картами платежных систем VISA и MasterCard, эмитированными в рублях Российской Федерации, по которым отсутствует запрет на осуществление переводов в оплату коммунальных услуг в сети Интернет. О наличии либо отсутствии такого запрета плательщик может уточнить в банке-эмитенте карты.<br>
                Списание денежных средств осуществляется 10 числа каждого месяца в сумме выставляемого Поставщиком счета на оплату, либо в фиксированной сумме, определенной плательщиком самостоятельно. Если 10 число выпадает на выходной или праздничный день – списание денежных средств осуществляется в первый рабочий день, следующий за 10 числом.<br>
                Внимание! В сумму выставляемого счета при первом списании денежных средств по услуге «Автоплатеж» включается задолженность по оплате за предыдущие платежные периоды.<br>
                3. Чтобы заказать услугу «Автоплатеж» плательщику необходимо в личном кабинете клиента Поставщика оформить электронную форму «Заявления о присоединении к услуге «Автоплатеж» (далее – Заявление) и осуществить привязку банковской карты к номеру лицевого счета клиента Поставщика. Регистрация заполненного плательщиком Заявления в базе данных Поставщика свидетельствует о согласии плательщика на оказание услуги «Автоплатеж».<br>
                Внимание! По номеру лицевого счета клиента Поставщика может быть оформлено только одно Заявление. Одновременное списание средств со счетов двух и более банковских карт не допускается.<br>
                Число Заявлений, оформленных плательщиком для предоставления услуги «Автоплатеж» по одной банковской карте, не ограничено. Эта услуга позволит оплачивать электроэнергию по лицевым счетам родителей, детей, друзей и т.д.).<br>
                4. Срок предоставления услуги «Автоплатеж» ограничен сроком действия банковской карты плательщика. По окончании срока действия банковской карты плательщику необходимо аннулировать заявление.<br>
                При получении новой банковской карты плательщик, желающий продолжить пользование услугой «Автоплатеж», оформляет в личном кабинете клиента Поставщика новое заявление.<br>
                5. Плательщик в любое время может отказаться от услуги «Автоплатеж». Для этого необходимо аннулировать Заявление в личном кабинете клиента Поставщика.<br>
                6. В случае нехватки денежных средств на счете банковской карты Плательщика, частичное списание платежа не осуществляется. Для гарантированного получения услуги «Автоплатеж» плательщику необходимо обеспечить достаточное количество денежных средств на счете банковской карты по состоянию на 10 число каждого месяца.<br>
                7. Поставщик не несет ответственности за:<br>
                — неправильное указание номера клиента Поставщика в Заявлении;<br>
                — отказ банка-эмитента карты в авторизации списания суммы платежа за электроэнергию;<br>
                — невозможность обслуживания плательщика по каким-либо независящим от него причинам, включая нарушение работы линий связи, неисправность оборудования и т.п.<br>
                8. Все споры и разногласия по вопросу предоставления услуги «Автоплатеж» решаются путем рассмотрения обращений плательщиков, адресованных Поставщику.<br>
                9. Настоящие условия являются публичной офертой. Заказ услуги «Автоплатеж» путем оформления и регистрации Заявления в базе данных Поставщика считаются акцептом данной оферты.<br>
            </div>
        </div>
        <div v-else-if="step2" class="scroll-text__simple">
            <p>Выберите, для какой карты подключить автоплатеж.<br> Списание суммы счета будет происходить каждое 10 число месяца.</p>
            <div class="payment-left">
                <div
                    v-if="currentAccount.bindings && currentAccount.customer_class != 'QL'"
                    v-for="(card, key) in currentAccount.bindings"
                    class="payment-card flex"
                >
                    <label class="radio">
                        <input
                            name="type"
                            :value="card.bindingId"
                            v-model="selectCard"
                            type="radio"
                            @change="bindingsCardSelect"
                        >
                        <span></span>
                    </label>
                    <div class="existing-card flex">
                        <img src="/assets/images/card.svg" alt="">
                        <span>{{card.displayLabel}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="step3" class="scroll-text__simple">
            <p>У вас нет сохраненных карт.<br> Для подключения автоплатежа проведите платеж, мы подключим для вашей карты автоплатеж.</p>
            <p>Списание суммы счета будет происходить каждое 10 число месяца.</p>
            <div class="payment-columns flex">
                <label class="input-label">К оплате:</label>
                <div class="payment-left">
                    <div v-if="currentAccount" class="input-wrap">
                        <input
                            class="input-text pay-summ"
                            type="text"
                            v-imask="rubl"
                            :value="currentAccount.saldo.balance.currentBalance > 0 ? currentAccount.saldo.balance.currentBalance : this.defSumm"
                        >
                    </div>
                </div>
            </div>
        </div>
        <label class="checkbox white large">
            <input v-model="checkbox" type="checkbox">
            <span>Я согласен с <a href="#">правилами оплаты</a></span>
            <div v-if="checkboxError" class="input-error">Установите флажок</div>
        </label>
        <div v-if="error" class="input-error">{{error}}</div>

        <div class="modal-button flex">
            <div v-if="!amount">
                <button v-if="currentAccount.bindings.length == 1" @click.prevent="autoPay" class="button">Подключить</button>
                <button v-else-if="step1 && currentAccount.bindings.length > 1 && !bindingsCard" @click.prevent="{{step2 = true; step1 = false}}" class="button">Подключить</button>
                <button v-else-if="step2 && currentAccount.bindings.length > 1 || bindingsCard" @click.prevent="autoPay" class="button">Подключить</button>
                <button v-else-if="!step3 && !step2 && !bindingsCard" class="button" @click.prevent="{{step3 = true; step1 = false}}">Подключить</button>
                <button v-if="step3" class="button" @click.prevent="payAction">Подключить</button>
            </div>
            <button v-else class="button" @click.prevent="payAction">Подключить</button>
            <button class="button reset" @click.prevent="$emit('showModal')">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"
import { IMaskDirective } from 'vue-imask'

export default {
    props: ['bindingsCard', 'amount'],
    name: 'modal-auto-pay',
    emits: ['showModal'],
    data() {
        return {
            checkbox: true,
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            checkboxError: false,
            error: '',
            step1: true,
            step2: false,
            step3: false,
            selectCard: false,
            defSumm: 100,
            rubl: {
                mask: 'num {руб.}',
                lazy: false,
                blocks: {
                    num: {
                        mask: Number,
                    }
                }
            },
        }
    },
    directives: {
        imask: IMaskDirective
    },
    computed: {
        currentAccount() {
            let account = this.$store.state.currentAccount

            if (account && account.bindings)
                this.selectCard = account.bindings[0].bindingId

            return account
        },
    },
    methods: {
        async autoPay(e) {
            e.target.disabled = true
            if (this.checkbox)
                try {
                    this.checkboxError = false
                    const response = await axios.post(
                        this.apiUrl + '/api/pay/autopay',
                        {
                            binding_id: this.bindingsCard ? this.bindingsCard : this.selectCard,
                            account_id: this.currentAccount.account_id
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.token
                            }
                        }
                    )
                    this.$emit('showModal')
                    this.$store.state.accounts = []
                    this.$store.commit('getAccounts')
                    this.$store.getters.currentAccount(this.currentAccount.account_id)
                } catch(e) {
                    if (e.response.data)
                        this.error = e.response.data.message

                    console.log(e.response);
                }
            else {
                e.target.disabled = false
                this.checkboxError = true
            }
        },
        async payAction() {
            if (this.checkbox) {
                this.checkboxError = false
                try {
                    let paySumm = document.getElementsByClassName('pay-summ')[0]
                    let amount

                    if (paySumm)
                        amount = Math.abs(paySumm.value.replace(' руб.', '').replace(',', '.'))
                    else if(this.amount)
                        amount = Math.abs(this.amount.replace(' руб.', '').replace(',', '.'))
                    
                    if (amount) {
                        let data = {
                            account_id: this.currentAccount.account_id,
                            amount: amount * 100,
                            bind: true,
                            use_autopay: this.amount ? true : false,
                        }

                        const response = await axios.post(
                            this.apiUrl + '/api/pay',
                            data,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + this.token
                                }
                            }
                        )

                        window.location.href = response.data.paymentLink
                    } else {
                        
                    }
                } catch (e) {
                    console.log(e)
                    console.log(e.response)
                }
            } else {
                this.checkboxError = true
            }
        },
    }
}
</script>