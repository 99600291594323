<template>
    <transition name="fade">
        <preloader-data v-if="loadDataStatus" />
    </transition>
    <router-view></router-view>
    <modal-win
        v-if="!loadDataStatus && showPayWin"
        :showDef="showPayWin"
        type="success"
        ref="modalSuccess"
        modalClass="modal-success"
    />
</template>

<script>

export default {
    data() {
        return {
            
        }
    },
    computed: {
        showPayWin() {
            return this.$store.state.showPayWin
        },
        loadDataStatus() {
            if (this.$store.state.status401 == 401) {
                this.$store.commit('logout')
                window.location.reload()
            }

            return this.$store.state.loadDataStatus
        }
    },
    mounted() {
        // this.$refs.modalSuccess.showModal()

        if (localStorage.getItem('token'))
            this.$store.commit('reloadPageSetValue')
        else
            this.$router.push('/auth/')

        window.addEventListener('offline', function(e) {
            console.log('offline');
        });

        window.addEventListener('online', function(e) {
            console.log('online');
        });
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
