<template>
    <div class="title">История</div>
    <div class="history-nav">
        <a
            class="button simple"
            href="#"
            :class="{active: meterReading}"
            @click.prevent="meterReading = !meterReading"
        >Показания</a>
        <a
            class="button simple"
            href="#"
            :class="{active: !meterReading}"
            @click.prevent="meterReading = !meterReading"
        >Оплаты</a>
    </div>
    <user-meters-data v-if="meterReading" />
    <user-payments v-else />
</template>

<script>
export default {
    name: 'history-page',
    data() {
        return {
            meterReading: true
        }
    }
}
</script>