<template>
    <div class="container flex">
        <side-bar />
        <div class="content">
            <header-block-profile />
            <div class="content-wrap">
                <user-contact />
                <user-receiving-ivoice />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        if (!this.$store.state.authStatus)
            this.$router.push('/auth/')
    }
}
</script>