<template>
    <div class="modal-title">Редактирование e-mail</div>
    <form class="modal-form">
        <input-text
            label       = "E-mail адрес"
            required    = "*"
            :errorText  = "updateError"
            placeholder = "E-mail адрес"
            maskVal     = "email"
            name        = "email"
            disabled    = "inputDisabled"
            :defValue   = "dataEmailValue"
        />
        <div class="modal-button flex">
            <orange-button
                text            = "Сохранить"
                @click.prevent  = "emailBillUpdate"
                ref             = "submitButton"
            />
            <button class="button reset" @click.prevent="closeModal">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"

export default {
    name: 'modal-edit-email-bill',
    props: ['dataClientNumber', 'dataEmailValue', 'inputName'],
    emits: ['showModal', 'setEmail'],
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            updateError: false,
            email: ''
        }
    },
    computed: {
        currentAccount() {
            return this.$store.state.currentAccount
        }
    },
    methods: {
        closeModal() {
            if (this.inputName) {
                let inputs = document.querySelectorAll('input[name="'+this.inputName+'"]')
                inputs = Array.from(inputs)

                inputs.forEach((item) => {
                    if (item.checked)
                        item.checked = false
                    else
                        item.checked = true
                })
            }

            this.$emit('setEmail', {set: false})
            this.$emit('showModal')
        },
        async emailBillUpdate() {
            try {
                let input = document.getElementsByClassName('input-text')
                let modal = document.getElementsByClassName('modal-win')[0]
                
                if (!input.email.value) {
                    this.updateError = 'Укажите E-mail'
                } else {
                    let data = {
                        accounts: [
                            {
                                client_number: modal.attributes['data-client-number'].value,
                                email_bill: input.email.value
                            }
                        ]
                    }

                    const response = await axios.patch(
                        this.apiUrl + '/api/user/accounts',
                        data,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.token
                            }
                        }
                    )

                    if (this.inputName)
                        this.$emit('setEmail', {set: true, email: input.email.value})

                    this.$emit('showModal')
                    this.$store.commit('setAccountEmailBill', {client_number: modal.attributes['data-client-number'].value, email_bill: input.email.value})
                }
            } catch (e) {
                console.log(e)
                if (e.response.data.message)
                    this.updateError = e.response.data.message

                console.log(e.response)
            }
        },
    }
}
</script>