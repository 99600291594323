<template>
    <div class="container">
        <div class="container-inner">
            <div class="reg-auth flex">
                <img src="/assets/images/auth-logo.svg" alt="">
                <div
                    class="reg-auth__wrap flex"
                    :class="showRegForm ? 'reg' : ''"
                >
                    <form class="reg-auth__form">
                        <div class="reg-auth__form-inner">
                            <!-- title -->
                            <div class="title">Личный кабинет</div>
                            <!-- switch -->
                            <div class="reg-auth__switch flex">
                                <a
                                    href="#"
                                    :class="!showRegForm ? 'active' : ''"
                                    @click="showRegForm = false"
                                >Вход</a>
                                <a
                                    href="#"
                                    :class="showRegForm ? 'active' : ''"
                                    @click="showRegForm = true"
                                >Регистрация</a>
                                <span
                                    class="switch__pointer"
                                    :class="showRegForm ? 'active-reg' : ''"
                                ></span>
                            </div>
                            <auth-from v-if="!showRegForm" />
                            <reg-form
                                v-else
                                @backAuth="getValue"
                            />
                        </div>
                    </form>
                    <div class="reg-auth__side flex">
                        <p>Мобильное приложение для оплаты и передачи показаний</p>
                        <div class="reg-auth__line">
                            <a target="_blank" href="https://apps.apple.com/ru/app/%D0%BB%D1%8D%D1%81%D0%BA-%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0/id1136740155">
                                <img src="/assets/images/apple-store.svg" alt="">
                            </a>
                            <img src="/assets/images/apple.png" alt="">
                        </div>
                        <div class="reg-auth__line">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.lesk.mobapp">
                                <img src="/assets/images/google-play.svg" alt="">
                            </a>
                            <img src="/assets/images/google.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-block />
    </div>
</template>

<script>
import AuthForm from "@/components/AuthReg/AuthForm"
import RegForm from "@/components/AuthReg/RegForm"

export default {
    name: 'auth-reg-window',
    component: {
        AuthForm,
        RegForm
    },
    data() {
        return {
            showRegForm: false
        }
    },
    computed: {
        regErrors() {
            if (this.$store.state.authErrors)
                this.setInputDisabled(false)

            return this.$store.state.authErrors
        },
    },
    methods: {
        getValue(e) {
            this.showRegForm = false
        }
    }
}
</script>