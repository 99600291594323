<template>
	<div class="preloader-data">
		<!-- <div class="loader-06"></div> -->
		<div class="loader-38"></div>
	</div>
</template>

<script>
export default {
	name: 'preloader-data'
}
</script>

<style scoped>
.preloader-data{
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(0, 0, 0, .3);
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2em;
}
.loader-06 {
	display: inline-block;
	width: 1em;
	height: 1em;
	color: inherit;
	vertical-align: middle;
	pointer-events: none;
	border: 0.5em solid #FFF;
	border-radius: 50%;
	-webkit-animation: loader-06 1s ease-out infinite;
	animation: loader-06 1s ease-out infinite;
}

@-webkit-keyframes loader-06 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-06 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/**/
.loader-38 {
  height: 0.1em;
  width: 0.1em;
  box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF;
  -webkit-animation: loader-38 6s infinite;
          animation: loader-38 6s infinite;
}

@-webkit-keyframes loader-38 {
  0% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF;
  }
  8.33% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF;
  }
  16.66% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF;
  }
  24.99% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  33.32% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF;
  }
  41.65% {
    box-shadow: 0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF;
  }
  49.98% {
    box-shadow: 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF;
  }
  58.31% {
    box-shadow: -0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  66.64% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  74.97% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  83.3% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  91.63% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  100% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF;
  }
}

@keyframes loader-38 {
  0% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF;
  }
  8.33% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF;
  }
  16.66% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF;
  }
  24.99% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  33.32% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF;
  }
  41.65% {
    box-shadow: 0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF;
  }
  49.98% {
    box-shadow: 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF;
  }
  58.31% {
    box-shadow: -0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  66.64% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  74.97% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  83.3% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, 0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  91.63% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF, -0.2em 0.2em 0 0.1em #FFF;
  }
  100% {
    box-shadow: -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF, -0.2em -0.2em 0 0.1em #FFF;
  }
}

</style>