<template>
    <div class="container flex">
        <side-bar />
        <div class="content">
            <header-block-small text="Настройки лицевого счета" />
            <div class="content-wrap">
                <div class="form-block">
                    <div class="title">Статус отношения к ЛС <span class="required">*</span></div>
                    <select-list
                        :relationType   = "true"
                        :list           = "userType"
                        :defValue       = "currentAccount.relation_type"
                        :label          = "false"
                        @listChange     = "listChange"
                    />
                </div>
                <div class="form-block">
                    <div class="title">Способ получения квитанций</div>
                    <p class="gray-hint">Откажитесь от печатной квитанции в пользу ее электронной версии.</p>
                    <div class="receiving-receipts">
                        <div class="table">
                            <ul class="thead">
                                <li>E-mail</li>
                                <li>Бумажный</li>
                                <li>E-mail для квитанций</li>
                                <li></li>
                                <li>Адрес для квитанций</li>
                                <li></li>
                            </ul>
                            <ul class="tbody">
                                <li>
                                    <label class="radio" :class="{blue: currentAccount.cis_division == 'GESK'}">
                                        <input
                                            v-if="currentAccount.email_bill_flag"
                                            type="radio"
                                            name="type"
                                            value="email"
                                            @change="selectTypeBill = 'email'"
                                            checked
                                        >
                                        <input
                                            v-else
                                            type="radio"
                                            name="type"
                                            value="email"
                                            @change="selectTypeBill = 'email'"
                                        >
                                        <span></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="radio" :class="{blue: currentAccount.cis_division == 'GESK'}">
                                        <input
                                            v-if="currentAccount.post_bill_flag"
                                            type="radio"
                                            name="type"
                                            value="post"
                                            @change="selectTypeBill = 'post'"
                                            checked
                                        >
                                        <input
                                            v-else
                                            type="radio"
                                            name="type"
                                            value="post"
                                            @change="selectTypeBill = 'post'"
                                        >
                                        <span></span>
                                    </label>
                                </li>
                                <li>
                                    {{currentAccount.email_bill ? currentAccount.email_bill : 'Не установлен'}}
                                </li>
                                <li>
                                    <a @click.prevent="showModalEditEmail" :data-client-number="currentAccount.client_number" :data-email-value="currentAccount.email_bill" href="#" class="table-edit"></a>
                                </li>
                                <li>
                                    {{currentAccount.bill_address ? currentAccount.bill_address : 'Не установлен'}}
                                </li>
                                <li>
                                    <a @click.prevent="showModalEditAddress" href="#" class="table-edit"></a>
                                </li>
                            </ul>
                        </div>
                        <!--  -->
                        <div class="table-mob">
                            <div class="table-mob__item">
                                <p>E-mail для квитанций</p>
                                <span>{{currentAccount.email_bill ? currentAccount.email_bill : 'Не установлен'}}</span>
                                <a href="#" class="edit-min"></a>
                            </div>
                            <div class="table-mob__item">
                                <p>Адрес для квитанций</p>
                                <span>{{currentAccount.bill_address ? currentAccount.bill_address : 'Не установлен'}}</span>
                                <a href="#" class="edit-min"></a>
                            </div>
                            <div class="table-mob__radio flex">
                                <label class="radio" :class="{blue: currentAccount.cis_division == 'GESK'}">
                                    <input
                                        v-if="currentAccount.email_bill_flag"
                                        type="radio"
                                        name="mob_type"
                                        value="email"
                                        @change="selectTypeBill = 'email'"
                                        checked
                                    >
                                    <input
                                        v-else
                                        type="radio"
                                        name="mob_type"
                                        value="email"
                                        @change="selectTypeBill = 'email'"
                                    >
                                    <span></span>
                                    <i>E-mail</i>
                                </label>
                                <label class="radio" :class="{blue: currentAccount.cis_division == 'GESK'}">
                                    <input
                                        v-if="currentAccount.post_bill_flag"
                                        type="radio"
                                        name="mob_type"
                                        value="post"
                                        @change="selectTypeBill = 'post'"
                                        checked
                                    >
                                    <input
                                        v-else
                                        type="radio"
                                        name="mob_type"
                                        value="post"
                                        @change="selectTypeBill = 'post'"
                                    >
                                    <span></span>
                                    <i>Бумажный</i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-block__buttons flex">
                    <a @click.prevent="setBillMethod" href="#" class="button">Сохранить</a>
                    <a @click.prevent="showModalDeleteInvoice" href="#" class="button reset">Удалить лицевой счет</a>
                </div>
            </div>
        </div>
    </div>
    <modal-win
        type="deleteInvoice"
        ref="modal"
    />
    <modal-win
        type="editAddressBill"
        :dataAddressValue="currentAccount.bill_address"
        ref="modalAddress"
    />
    <modal-win
        type="editEmailBill"
        :dataEmailValue="currentAccount.email_bill"
        :dataClientNumber="currentAccount.client_number"
        ref="modalEmail"
    />
</template>

<script>
import axios from "axios"

export default {
    name: 'invoice-page',
    data() {
        return {
            clientType: '',
            userType: [
                'Собственник',
                'Арендатор',
                'Зарегистрированный',
                'Проживает',
                'Другое',
            ],
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            selectTypeBill: ''
        }
    },
    computed: {
        currentAccount() {
            let account = this.$store.state.currentAccount
            this.clientType = account.relation_type

            this.selectTypeBill = account.email_bill_flag ? 'email' : account.post_bill_flag ? 'post' : ''

            return account
        }
    },
    mounted() {
        if (!this.$store.state.authStatus)
            this.$router.push('/auth/')
    },
    methods: {
        listChange(e) {
            this.clientType = e.value
        },
        getValue(e) {
            if (e.name == 'client-number') {
                this.clientNumber = e.value
            }
            if (e.name == 'agreement') {
                this.agreement = e.checked
            }
        },
        showModalDeleteInvoice() {
            this.$refs.modal.showModal()
        },
        showModalEditEmail(e) {
            this.$refs.modalEmail.showModal()
        },
        showModalEditAddress(e) {
            this.$refs.modalAddress.showModal()
        },
        setInputDisabled(status) {
            if (this.$refs.submitButton)
                this.$refs.submitButton.setDisabled(status)
        },
        async setBillMethod(e) {
            try {
                let data = {
                    accounts: [
                        {
                            client_number: this.currentAccount.client_number,
                            relation_type: this.clientType
                        }
                    ]
                }

                let value = this.selectTypeBill

                if (value == 'email') {
                    data.accounts[0].email_bill_flag = true
                    data.accounts[0].post_bill_flag = false
                } else {
                    data.accounts[0].email_bill_flag = false
                    data.accounts[0].post_bill_flag = true
                }

                const response = await axios.patch(
                    this.apiUrl + '/api/user/accounts',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                
                this.$store.commit('accountUpdate', data.accounts[0])
            } catch (e) {
                console.log(e)
            }
        },
    }
}
</script>

<style scoped>
.content-wrap .input-label{
    display: none;
}
</style>