<template>
    <div class="input-wrap">
        <label v-if="label" class="input-label">{{label}}<span class="required">*</span></label>
        <div
            v-if="!relationType"
            class="select"
            :class="showList ? 'opened' : ''"
            tabindex="0"
            @blur="showList = false"
        >
            <div
                class="select-text"
                @click="showList = !showList">{{value ? value : placeholder}}</div>

            <div v-show="showList" class="select-options">
                <div
                    v-for="(type, i) in list"
                    :key="i"
                    class="select-options__item"
                    :data-value="type"
                    @click="selectItem"
                >{{type}}</div>
            </div>
        </div>
        <div v-else-if="currentAccount" class="select" :class="showList ? 'opened' : ''">
            <div
                class="select-text"
                @click="showList = !showList">{{value ? value : placeholder}}</div>

            <div v-show="showList" class="select-options">
                <div
                    v-for="(type, i) in list"
                    :key="i"
                    class="select-options__item"
                    :class="{selected: value == type}"
                    :data-value="type"
                    @click="selectItem"
                >{{type}}</div>
            </div>
        </div>
        <span v-if="errorText" class="input-error">{{errorText}}</span>
    </div>
</template>

<script>
export default {
    name: 'select-list',
    props: ['list', 'errorText', 'label', 'relationType', 'placeholder'],
    data() {
        return {
            showList: false,
            value: '',
            currentEl: false
        }
    },
    computed: {
        currentAccount() {
            let account = this.$store.state.currentAccount

            this.value = account.relation_type

            return account
        }
    },
    methods: {
        selectItem(e) {
            if (this.currentEl)
                this.currentEl.classList.remove('selected')

            this.value = e.target.dataset.value
            e.target.classList.add('selected')
            this.currentEl = e.target
            
            this.showList = false

            this.$emit('listChange', this)
        }
    }
}
</script>