<template>
    <input-text
        label       = "Номер телефона"
        required    = "*"
        :errorText  = "authErrors.phone ? authErrors.phone[0] : ''"
        placeholder = "+7 (___) ___-__-__"
        maskVal     = "phone"
        name        = "phone"
        ref         = "phoneInput"
        disabled    = "inputDisabled"
        @onComplete = "getValue"
        @onAccept   = "clearValue"
    />
    <input-password
        label       = "Пароль"
        required    = "*"
        :errorText  = "authErrors.password ? authErrors.password[0] : ''"
        placeholder = "Пароль"
        name        = "password"
        ref         = "passwordInput"
        @onComplete = "getValue"
    />
    <p
        v-if="authErrors.message"
        class="input-error"
    >{{authErrors.message}}</p>

    <div class="reg-auth__button">
        <orange-button
            text            = "Войти"
            @click.prevent  = "login"
            ref             = "submitButton"
        />
        <div class="flex">
            <a href="#" @click.prevent="showModal">Забыли пароль?</a>
            <a class="old-lk" href="https://lk.lesk.ru/login" target="_blank">Вход в старый ЛК</a>
        </div>
    </div>
    <modal-win
        type    = "forgotPassword"
        ref     = "modal"
    />
</template>

<script>
import InputText from '@/components/UI/InputText'
import InputPassword from '@/components/UI/InputPassword'
import OrangeButton from '@/components/UI/OrangeButton'

export default {
    name: 'auth-from',
    data() {
        return {
            phone: '',
            password: '',
            inputDisabled: false,
        }
    },
    components: {
        InputText,
        InputPassword,
        OrangeButton,
    },
    computed: {
        authErrors() {
            if (this.$store.state.authErrors)
                this.setInputDisabled(false)
            else if (this.$store.state.authStatus)
                this.$router.push('/')

            return this.$store.state.authErrors
        }
    },
    methods: {
        getValue(e) {
            if (e.name == 'phone')
                this.phone = e.value

            if (e.name == 'password')
                this.password = e.value
        },
        clearValue() {
            this.phone = ''
        },
        login() {
            this.$store.commit('login', {
                phone: this.phone,
                password: this.password,
            })
            this.setInputDisabled(true)
        },
        setInputDisabled(status) {
            if (this.$refs.submitButton)
                this.$refs.submitButton.setDisabled(status)
        },
        showModal() {
            this.$refs.modal.showModal()
        }
    }
}
</script>