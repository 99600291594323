<template>
	<div class="modal-title">Уведомления</div>
	<div class="scroll-text">
		<form class="modal-form">
			<div v-for="item in notice" class="notifications-item" :class="{new: !item.is_read}">
			    <div class="notifications-title flex">
			        <span>{{item.title}}</span>
			        <span class="notifications-time">{{item.created_at_time}}</span>
			    </div>
			    <div class="notifications-body" v-html="item.text"></div>
			</div>
		</form>
	</div>
</template>

<script>

export default {
	name: 'modal-notice',
	emits: ['showModal'],
	data() {
		return {
			
		}
	},
	computed: {
		notice() {
			let notice = this.$store.state.notice
			return notice.sort((a, b) => {return b.created_at - a.created_at})
		}
	},
}
</script>