<template>
    <div class="input-wrap" :class="flex">
        <label v-if="label" class="input-label">{{ label }}<span v-if="required" class="required">*</span></label>
        <div :class="{'input-wrap__hint': textHint}">
            <input
                v-if="maskVal == 'phone'"
                class="input-text"
                type="text"
                :class="errorText ? 'error' : ''"
                :placeholder="placeholder"
                :value="defValue"
                :name="name"
                :disabled="inputDisabled"
                v-imask="phone"
                @accept="onAccept"
                @complete="onComplete"
            >
            <input
                v-else-if="maskVal == 'snils'"
                class="input-text"
                type="text"
                :class="errorText ? 'error' : ''"
                :placeholder="placeholder"
                :value="value"
                :name="name"
                :disabled="inputDisabled"
                v-imask="snils"
                @accept="onAccept"
                @complete="onComplete"
            >
            <input
                v-else-if="maskVal == 'client-number'"
                class="input-text"
                type="text"
                :class="errorText ? 'error' : ''"
                :placeholder="placeholder"
                :value="value"
                :name="name"
                :disabled="inputDisabled"
                v-imask="clientNumber"
                @accept="onAccept"
                @complete="onComplete"
            >
            <input
                v-else-if="maskVal == 'email'"
                class="input-text"
                type="text"
                :class="errorText ? 'error' : ''"
                :placeholder="placeholder"
                :value="defValue"
                :name="name"
                :disabled="inputDisabled"
                v-imask="email"
                @accept="onAccept"
                @complete="onComplete"
            >
            <input
                v-else-if="maskVal == 'phone-email'"
                class="input-text"
                type="text"
                :class="errorText ? 'error' : ''"
                :placeholder="placeholder"
                :value="value"
                :name="name"
                :disabled="inputDisabled"
                v-imask="phoneEmail"
                @accept="onAccept"
                @complete="onComplete"
            >
            <input
                v-else-if="maskVal == 'invoice'"
                :class="class"
                type="text"
                readonly
                :value="inputValue"
                v-imask="invoice"
            >
            <input
                v-else-if="maskVal == 'onlyText'"
                class="input-text"
                :class="class"
                type="text"
                :value="defValue"
                v-imask="onlyText"
            >
            <input
                v-else-if="maskVal == 'number'"
                class="input-text"
                :class="class"
                type="text"
                :placeholder="placeholder"
                v-imask="number"
                @complete="onComplete"
                :name="name"
                :data-readsequence="dataReadsequence"
                :data-master-config-id="dataMasterConfigId"
                :data-premise-id="dataPremiseId"
                :data-old-val="dataOldVal"
                :data-tariff="dataTariff"
                :readonly="readonly"
            >
            <div v-else-if="inputWrapClass == 'calendar-wrap'" class="calendar-wrap">
                <input
                    class="input-text"
                    type="text"
                    :class="errorText ? 'error' : ''"
                    :placeholder="placeholder"
                    :value="value"
                    :name="name"
                    :disabled="inputDisabled"
                    @change="onComplete"
                >
                <a href="#" class="calendar-wrap__show"></a>
            </div>
            <input
                v-else
                class="input-text"
                type="text"
                :class="errorText ? 'error' : ''"
                :placeholder="placeholder"
                :value="defValue"
                :name="name"
                :disabled="inputDisabled"
                :maxlength="maxlength"
                @change="onComplete"
            >
            <popup-hint v-if="textHint" :text="textHint" />
        </div>
        <span v-if="errorText && errorText != 'no-text'" class="input-error">{{errorText}}</span>
        <p v-if="inputHint" class="input-text__hint">{{inputHint}}</p>
    </div>
</template>

<script>
import { IMaskDirective } from 'vue-imask'

export default {
    name: 'input-text',
    props: [
        'label',
        'required',
        'errorText',
        'placeholder',
        'maskVal',
        'maskComplete',
        'name',
        'inputWrapClass',
        'inputHint',
        'class',
        'inputValue',
        'textHint',
        'dataReadsequence',
        'dataMasterConfigId',
        'dataPremiseId',
        'dataTariff',
        'flex',
        'readonly',
        'defValue',
        'maxlength',
        'dataOldVal',
    ],
    data() {
        return {
            error: false,
            inputDisabled: false,
            value: '',
            maskValue: '',
            onlyText: {
                mask: /^[A-Za-zА-Яа-я]+$/
            },
            phone: {
                mask: '+{7} (000) 000-00-00'
            },
            clientNumber: {
                mask: '000 00 00'
                // mask: Number
            },
            email: {
                mask: /^\S*@?\S*$/
            },
            snils: {
                mask: '000-000-000 00'
            },
            invoice: {
                mask: '000 00 00'
            },
            phoneEmail: {
                mask: [
                    {
                        mask: '+{7} (000) 000-00-00'
                    },
                    {
                        mask: /^\S*@?\S*$/
                    }
                ]
            },
            number: {
                mask: Number
            }
        }
    },
    directives: {
        imask: IMaskDirective
    },
    methods: {
        onAccept(e) {
            const maskRef = e.detail
            if (maskRef && e.target.name == 'phone' || maskRef && e.target.name == 'reset-password') {
                this.value = maskRef.unmaskedValue.replace('7', '')
                this.maskValue = maskRef.value
            } else {
                this.value = e.target.value
            }

            this.$emit('onAccept', this)
        },
        onComplete(e) {
            if (!this.maskVal)
                this.value = e.target.value
            
            this.$emit('onComplete', this)
        },
        showError() {
            this.error = true
        },
        hideError() {
            this.error = false
        },
        setDisabled(status) {
            this.inputDisabled = status
        }
    }
}
</script>