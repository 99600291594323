<template>
    <label
        class="checkbox"
        :class="class"
    >
        <input
            type        = "checkbox"
            :name       = "name"
            :disabled   = "inputDisabled"
            v-model     = "checked"
            @change     = "onComplete"
        >
        <span v-html="text"></span>
        <div v-if="error" class="input-error">{{errorText}}</div>
    </label>
</template>

<script>
export default {
    name: 'input-checkbox',
    props: ['text', 'name', 'errorText', 'class'],
    data() {
        return {
            checked: false,
            error: false,
            inputDisabled: false
        }
    },
    methods: {
        onComplete(e) {
            this.$emit('onComplete', this)
        },
        showError() {
            this.error = true
        },
        hideError() {
            this.error = false
        },
        setDisabled(status) {
            this.inputDisabled = status
        }
    }
}
</script>